//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import jq from "jquery";
export default {
  data() {
    return {
      bannerSlick: {
        slidesToShow: 1,
        arrows: true,
        slidesToScroll: 1,
        dots: true,
        fade: true,
        autoplay: false,
        autoplaySpeed: 5000,
        lazyLoad: "ondemand",
        responsive: [
          {
            breakpoint: 768,
            settings: {
              arrows: false,
              fade: true,
              dots: true,
              autoplay: false,
              autoplaySpeed: 5000,
              lazyLoad: "ondemand",
            },
          },
          {
            breakpoint: 450,
            settings: {
              arrows: false,
              dots: true,
              fade: true,
              autoplay: false,
              autoplaySpeed: 5000,
              lazyLoad: "ondemand",
            },
          },
        ],
      },
      sliderH: 0,
    };
  },
};
