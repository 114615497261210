//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import jq from "jquery";
import NavBar from "~/components/NavBar.vue";
import Landing from "~/components/Landing.vue";
import HomeSlider from "~/components//HomePage/HomeSlider.vue";
import CurrentCommunity from "~/components/HomePage/CurrentCommunity.vue";
import Testimonial from "~/components/HomePage/Testimonial.vue";
import HomeBuilderStory from "~/components/HomePage/HomeBuilderStory.vue";
import NewsletterRegistration from "~/components/NewsletterRegistration.vue";
import Foot from "~/components/Foot.vue";
export default {
    head: {
        title: "HOME - Tribute Communities",
    },
    components: {
        NavBar,
        Landing,
        HomeSlider,
        CurrentCommunity,
        HomeBuilderStory,
        NewsletterRegistration,
        Testimonial,
    },
    data() {
        return {
            currentPageloaded: false,
        };
    },
    mounted() {
        this.$nextTick(() => {
            this.currentPageloaded = true;
            this.$root.$on("scrolltoSection", (data) => {
                this.goToId(data.place);
            });

            setTimeout(() => {
                if (this.currentPageloaded == true) {
                    this.checkHash()
                }
            }, 0);
        })
    },
    methods: {
        checkHash() {
            //for web page scroll down
            // e.g./artistry/?goto=floorPlan
            let hash = this.$route.query["goto"];
            console.log(hash);
            if (hash != undefined) {
                this.$root.$emit('scrolltoSection', {
                    place: hash
                });
            }
        },
        goToId(id) {
            let adjuster = 63;
            var offsetT = $("#" + id).offset();
            // console.log("offsetT: "+offsetT);
            if (offsetT != undefined) {
                $("html, body").animate({
                        scrollTop: offsetT.top - adjuster,
                    },
                    500
                );
            }
        },
    },
    computed: {
        // navShorten() {
        //   // return false;
        //   if (this.scrollFromTop > 10) {
        //     console.log("true");
        //     return true;
        //   } else {
        //     console.log("false");
        //     return false;
        //   }
        // }
    },
};
