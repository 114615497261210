//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import jq from "jquery";
import * as regExp from "~/modules/RegExp";
export default {
  data() {
    return {
      form: {
        registerName: "",
        // nameStatus: null,
        registerEmail: "",
        // emailStatus: null
        postFirstName: "",
        postLastName: "",
        registerAgent:"NO",
      },
      alert: {
        nameError: false,
        emailError: false,
      },
    };
  },
  mounted() {},
  methods: {
    organizeName(regName) {
      if (regName != "" && regName.indexOf(" ") == -1) {
        return regName;
      } else {
        let subName = regName.trim().split(/\s+/);
        return subName;
      }
    },
    submitForm() {
      if (this.submitReady) {
        this.alert.nameError = false;
        this.alert.emailError = false;

        let form = this.form;
        let _this = this;

        let organizedName = this.organizeName(this.form.registerName);
        if (organizedName.length > 1 && typeof organizedName == "object") {
          this.form.postFirstName = organizedName[0];
          this.form.postLastName = organizedName[1];
        } else {
          this.form.postFirstName = organizedName;
          this.form.postLastName = "";
        }

        jq.ajax({
          method: "POST",
          // url: "/php/newsLetterReg.php",
          // url: "/php/allListSubscription.php",
          url: "/php/allListSubscriptionCorporate.php",
          crossDomain: true,
          data: {
            email: form.registerEmail,
            firstname: form.postFirstName,
            lastname: form.postLastName,
            Areyouabroker:form.registerAgent,
          },
          success: function(data) {
            _this.$router.push({
              path: "thankyou-news-letter",
            });
            // console.log("success");
            // console.log(data);
          },
        }).done(function(msg) {
          // console.log("done");
          // console.log(msg);
        });
      } else {
        //if any of input is illegal
        if (!this.nameValid) {
          this.alert.nameError = true;
        } else {
          this.alert.nameError = false;
        }

        if (!this.emailValid) {
          this.alert.emailError = true;
        } else {
          this.alert.emailError = false;
        }
      }
    },
  },
  computed: {
    emailValid() {
      return regExp.emailRegExp(this.form.registerEmail);
    },
    nameValid() {
      console.log(this.organizeName(this.form.registerName));
      let organizedName = this.organizeName(this.form.registerName);
      for (let index = 0; index < organizedName.length; index++) {
        if (!regExp.nameRegExp(organizedName[index])) {
          return false;
        }
      }
      return true;
    },
    submitReady() {
      if (this.nameValid && this.emailValid) {
        return true;
      }
      return false;
    },
  },
};
