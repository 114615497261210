//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CurrentCommunityJson from "@/json/current_community.json";
// import gsap from "gsap";
import * as animJS from "~/modules/Animation";
export default {
  data() {
    return {
      // activedComm: "viewall",
      activedComm: "",
      activedCommData: [],
      curCommunities: [],
    };
  },
  beforeMount() {
    this.curCommunities = CurrentCommunityJson.data;
    // this.activedCommData = this.curCommunities;
    this.activedCommData = [];//for collapse THE COMMUNITIES setction
  },
  mounted() {
    // console.log(this.curCommunities);
  },
  updated() {
    this.$nextTick(() => {
      // console.log("Update");
      animJS.gsapUpdateDom(
        ".curComInfoContainer",
        { opacity: 0, scale: 0.8 },
        { opacity: 1, scale: 1, ease: "Power4.in,", duration: 0.5 }
      );
    });
  },
  methods: {
    getActivedCommData(data) {
      this.activedComm = data;
      let curCom = this.curCommunities;
      let newCurCom = [];
      if (this.activedComm == "viewall") {
        this.activedCommData = this.curCommunities;
      // } else if(this.activedComm == "") {
      //   this.activedCommData = []
      } else {
        for (let index = 0; index < curCom.length; index++) {
          if (curCom[index]["category"] == this.activedComm) {
            newCurCom.push(curCom[index]);
          }
        }
        this.activedCommData = newCurCom;
      }
    },
    emptyActivedCommData(){
        this.activedComm = "";
        this.activedCommData = [];
    }
    // beforeEnter(){
    //   console.log("before");
    // },
    // enter(){
    //   console.log("enter");
    // },
    // leave(){
    //   console.log("leave");
    // }
  },
};
