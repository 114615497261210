let emailRegExp = function(emailAddress) {
  let emailRegExpr = /^[0-9a-zA-Z_.-]+[@][0-9a-zA-Z_.-]+([.][a-zA-Z]+){1,2}$/;
  return emailRegExpr.test(emailAddress);
};
let na_meRegExp = function(name) {
  let nameRegExpr = /^[A-Za-z]+$/;
  if (name.indexOf(" ") == -1) {
    return nameRegExpr.test(name);
  } else {
    let subName = name.trim().split(/\s+/);
    for (let index = 0; index < subName.length; index++) {
      if (!nameRegExpr.test(subName[index])) {
        return false;
      }
    }
    return true;
  }
};
let nameRegExp = function(name) {
  let nameRegExpr = /^[A-Za-z]+$/;
  let httpRegExpr = /(http:\/\/|https:\/\/|:\/\/)/i;
  return nameRegExpr.test(name) && !httpRegExpr.test(name);
  // return !nameRegExpr2.test(name);
};
let noSpecialSymbol = function(word) {
  // let noSpecialSymbol = /^[^$|\/|:]+$/;
  let noSpecialSymbol = /^[^$]+$/;
  let httpRegExpr = /(http:\/\/|https:\/\/|:\/\/)/i;
  return noSpecialSymbol.test(word) && !httpRegExpr.test(word);
};

export { emailRegExp, nameRegExp, na_meRegExp, noSpecialSymbol };
