import { render, staticRenderFns } from "./index.vue?vue&type=template&id=64f194ca&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64f194ca",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Landing: require('/Volumes/4TB-PRO-BLADE/projects/TributeComunityPROJECT/tribute-community-website/components/Landing.vue').default,NavBar: require('/Volumes/4TB-PRO-BLADE/projects/TributeComunityPROJECT/tribute-community-website/components/NavBar.vue').default,HomeSlider: require('/Volumes/4TB-PRO-BLADE/projects/TributeComunityPROJECT/tribute-community-website/components/HomePage/HomeSlider.vue').default,HomeBuilderStory: require('/Volumes/4TB-PRO-BLADE/projects/TributeComunityPROJECT/tribute-community-website/components/HomePage/HomeBuilderStory.vue').default,NewsletterRegistration: require('/Volumes/4TB-PRO-BLADE/projects/TributeComunityPROJECT/tribute-community-website/components/NewsletterRegistration.vue').default,Foot: require('/Volumes/4TB-PRO-BLADE/projects/TributeComunityPROJECT/tribute-community-website/components/Foot.vue').default})
